import { FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconLoader from "../IconLoader";

import color from "@/styles/color";

type LinearDeterminateProps = {
  progress: number;
};

const CustomLinearProgress = styled(LinearProgress)({
  height: 8,
  borderRadius: 4,
  backgroundColor: "#F2A7A3",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#EA0301",
    borderRadius: 4,
  },
});

const LinearDeterminate: FC<LinearDeterminateProps> = ({ progress }) => {
  const val: number = progress || 0;
  return (
    <Stack flexDirection="row" alignItems="center" position="relative">
      <CustomLinearProgress
        variant="determinate"
        value={val}
        sx={{
          flex: 1,
          width: { xs: "100%", sm: "100%" },
          color: color.red,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: `${val}%`,
          transition: "all linear 0.3s",
        }}
      >
        <IconLoader
          // viewBox="0 0 267 217"
          iconName="CarProgress"
          sx={{
            fontSize: "45px",
            position: "relative",
            // left: "50%",
            left: "-2%",
            // transform: "translateX(-50%)",
          }}
        />
      </Box>
    </Stack>
  );
};

export default LinearDeterminate;
