export enum MasterDataSearchKeyWordEnum {
  PROVINCE = "Province",
  BRAND = "Brand",
  MODEL = "Model",
  GRADE = "Grade",
  CAR_TYPE = "CarType",
  CLASS = "Class",
  PICKUP_TYPE = "PickupType",
  COLOR = "Color",
  GEAR = "Gear",
  FUEL_TYPE = "FuelType",
  DEALER = "Dealer",
  BRANCH = "Branch",
  YEAR = "Year",
  MILEAGE = "Mileage",
  PRICE = "Price",
  WHEEL_DRIVE = "WheelDrive",
  DOOR = "Door",
  UNIQUE_SELL_POINT = "UniqueSellingPoint",
  TAGS = "Tags",
  PROMOTION = "Promotion",
  CONTENT = "Content",
  REGION = "Region",
  INSTALLMENT = "Installment",
  BRANDSEARCH = "BrandSearch",
  DEALERPROVINCE = "DealerProvince"
}
