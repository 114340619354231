import { MasterDataSearchKeyWordEnum } from "@/libs/enum/master-search-key.enum";
import { VehicleSortEnum } from "@/libs/enum/wtb-query-key.enum";
import { VehicleCard } from "@/libs/types/vehicle.type";
import { ViewFilter } from "@/libs/types/want-to-buy.type";
import { create } from "zustand";

type SearchVehicle = {
  brand?: string;
  model?: string;
  province?: string;
  carType?: string;
};

type WantToBuyStore = {
  vehicleList: Nullable<VehicleCard>;
  setVehicleList: (vehicleList: Nullable<VehicleCard>) => void;

  viewFilter: ViewFilter;
  setViewFilter: (viewFilter: ViewFilter) => void;
  initialFilter: (viewFilter: ViewFilter) => void;
  resetFilter: () => void;

  generateUrlSlug: (filter?: SearchVehicle) => string;
  generateFilterCookie: () => string;
};

const initialState = {
  viewFilter: {
    page: 1,
    sort: VehicleSortEnum.NewArrival,
    isCertified: false,
    viewMode: "vertical",
  } as ViewFilter,
};

const excludedKeys = new Set([
  MasterDataSearchKeyWordEnum.PROVINCE,
  MasterDataSearchKeyWordEnum.CAR_TYPE,
  MasterDataSearchKeyWordEnum.BRAND,
  MasterDataSearchKeyWordEnum.MODEL,
  MasterDataSearchKeyWordEnum.YEAR,
  MasterDataSearchKeyWordEnum.GRADE,
]);

export const useWantToBuyStore = create<WantToBuyStore>((set, get) => ({
  vehicleList: null,
  setVehicleList: (vehicleList: Nullable<VehicleCard>) => {
    set({ vehicleList });
  },
  viewFilter: initialState.viewFilter,
  initialFilter: (viewFilter: ViewFilter) => {
    const viewFilterList = get().viewFilter;
    set({
      viewFilter: {
        ...viewFilter,
        viewMode: viewFilterList.viewMode ?? initialState.viewFilter.viewMode,
      },
    });
  },
  resetFilter: () => {
    set({
      viewFilter: initialState.viewFilter,
    });
  },
  setViewFilter: (viewFilter: ViewFilter) => {
    set({ viewFilter });
  },
  generateUrlSlug: () => {
    // /home/used-car/{brand}/{model}+{grade}/{year}/{province}/{cartype}/{price-range}/{installment-range}/{mileage-range}/{color}/{gear}/{fuelType}/{wheelDrive}/{door}/{uniqeSellingPoint}/{tag}
    const viewFilterList = get().viewFilter;
    const slugs: { [key: string]: string } = {
      brandSlug: "",
      modelSlug: "",
      gradeSlug: "",
      yearSlug: "",
      provinceSlug: "",
      carTypeSlug: "",
      priceSlug: "",
      // installmentSlug: "",
      // mileageSlug: "",
      // colorSlug: "",
      // gearSlug: "",
      // fuelTypeSlug: "",
      // wheelDriveSlug: "",
      // doorSlug: "",
      // uniqeSellingPointSlug: "",
      // tagSlug: "",
    };

    // // Helper function to set default slugs when necessary
    const setDefaults = (defaultValues: { [key: string]: string }) => {
      for (const [key, defaultValue] of Object.entries(defaultValues)) {
        slugs[key] ||= defaultValue;
      }
    };

    const updateSlug = (key: string, value: string) => {
      slugs[key] = encodeURIComponent(value.toLowerCase().replaceAll(" ", ""));
    };

    const updateRangeSlug = (key: string, range: FilterMinMax) => {
      slugs[key] = encodeURIComponent([range.Min, range.Max].join("-"));
    };

    const processViewFilter = (viewFilterList: any) => {
      const defaultSlugValues = {
        brandSlug: "allbrand",
        modelSlug: "allmodel",
        gradeSlug: "allgrade",
        yearSlug: "allyear",
        provinceSlug: "allprovince",
        carTypeSlug: "allcartype",
      };

      const { Brand, Model, Grade, Year, Province, CarType, Price } = viewFilterList;

      if (Brand?.Name) {
        const brandName = Brand.Name.toLowerCase();
        slugs.brandSlug = brandName === "all brand" ? "allbrand" : brandName;
      }

      if (Model?.Name) {
        const modelName = Model.Name.toLowerCase().replaceAll(" ", "-");
        slugs.modelSlug = modelName === "all model" ? "allmodel" : encodeURIComponent(modelName);
      }

      if (Grade?.Name) {
        const gradeName = Grade.Name.toLowerCase().replaceAll(" ", "-");
        slugs.gradeSlug = gradeName === "all grade" ? "allgrade" : encodeURIComponent(gradeName);
      }

      if (Year && (Year.Min !== 0 || Year.Max !== 0)) {
        updateRangeSlug("yearSlug", Year as FilterMinMax);
        slugs.brandSlug ||= "allbrand";
        slugs.modelSlug ||= "allmodel";
        slugs.gradeSlug ||= "allgrade";
      }

      if (Province?.Name) {
        slugs.provinceSlug = Province.Name.toLowerCase();
        slugs.brandSlug ||= "allbrand";
        slugs.modelSlug ||= "allmodel";
        slugs.gradeSlug ||= "allgrade";
        slugs.yearSlug ||= "allyear";
      }

      if (CarType?.Name) {
        updateSlug("carTypeSlug", CarType.Name);
        slugs.brandSlug ||= "allbrand";
        slugs.modelSlug ||= "allmodel";
        slugs.gradeSlug ||= "allgrade";
        slugs.yearSlug ||= "allyear";
        slugs.provinceSlug ||= "allprovince";
      }

      if (Price) {
        if (Price.Min === 0 && Price.Max === 0) {
          slugs.priceSlug = "";
        } else {
          updateRangeSlug("priceSlug", Price as FilterMinMax);
          setDefaults(defaultSlugValues);
        }
      }

      // Return the non-empty slugs joined by "/"
      return Object.values(slugs)
        .filter((slug) => slug !== "")
        .join("/");
    };

    // Example usage:
    const urlSlug = processViewFilter(viewFilterList);
    return urlSlug;
  },
  generateFilterCookie: () => {
    const viewFilterList = get().viewFilter;
    const otherFilter = Object.entries(viewFilterList).reduce((acc, [key, value]) => {
      if (!excludedKeys.has(key as MasterDataSearchKeyWordEnum)) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});
    return JSON.stringify({ ...otherFilter });
  },
}));
