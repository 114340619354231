import { Box, Stack } from "@mui/material";

import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { FindMyCarPageEnum } from "@/libs/enum/find-my-car.enum";
import color from "@/styles/color";

import FindMyCarLanding from "./FindMyCarLanding";
import FindMyCarAdvise from "./FindMyCarAdvise";
import FindMyCarContact from "./FindMyCarContact";

interface FindMyCarContentType {
  handleClose: () => void;
}

const FindMyCarContent = ({ handleClose }: FindMyCarContentType) => {
  const { currentPage } = useFindMyCarStore();

  return (
    <Box
      component={"div"}
      sx={{
        width: "100%",
        bgcolor: color.white,
        borderRadius: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: { xs: "16px" },
      }}
    >
      <Stack
        sx={{
          paddingY: { xs: 3, sm: 0 },
        }}
      >
        {currentPage === FindMyCarPageEnum.Landing && <FindMyCarLanding />}
        {currentPage === FindMyCarPageEnum.Advise && <FindMyCarAdvise />}
        {currentPage === FindMyCarPageEnum.Contact && (
          <FindMyCarContact handleClose={handleClose} />
        )}
      </Stack>
    </Box>
  );
};

export default FindMyCarContent;
