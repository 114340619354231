export enum FindMyCarPageEnum {
  Landing = "landing",
  Advise = "advise",
  Contact = "contact",
}

export enum FindMyCarAdviseStepEnum {
  Region = "region",
  Province = "province",
  Budget = "budget",
  CarType = "car-type",
}

export enum FindMyCarQueryEnum {
  CUSTOMER_CONTACT = "customer-contact",
}
