export const adviseFilterBudgetRange = [
  {
    key: "lower_than_400000",
    translation: {
      key: "lower_than",
      value: {
        min: 400000,
      },
    },
    value: {
      Min: 0,
      Max: 399999,
    },
  },
  {
    key: "400000_to_600000",
    translation: {
      key: "min_max",
      value: {
        min: 400000,
        max: 600000,
      },
    },
    value: {
      Min: 400000,
      Max: 600000,
    },
  },
  {
    key: "600001_to_1000000",
    translation: {
      key: "min_max",
      value: {
        min: 600001,
        max: 1000000,
      },
    },
    value: {
      Min: 600001,
      Max: 1000000,
    },
  },
  {
    key: "more_than_1000000",
    translation: {
      key: "more_than",
      value: {
        min: 1000000,
      },
    },
    value: {
      Min: 1000001,
      Max: 9999999,
    },
  },
];
