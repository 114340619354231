"use client";

import { useCallback, useMemo } from "react";
import { Box, Stack, SxProps, Toolbar, Typography } from "@mui/material";
import { useTranslation } from "@/i18n/client";

import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { useReadScreen } from "@/hooks/useReadScreen.hook";
import { FindMyCarAdviseStepEnum, FindMyCarPageEnum } from "@/libs/enum/find-my-car.enum";
import color from "@/styles/color";

import DrawerBottomCustom from "@/components/Drawer/DrawerBottomCustom";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import ModalCloseOutBoxCustom from "@/components/Modal/ModalCloseOutBoxCustom";
import FindMyCarContent from "./components/FindMyCarContent";

const FindMyCarDrawer = () => {
  const { t } = useTranslation();
  const { isLessThanSm } = useReadScreen();

  const {
    currentAdviseStep,
    currentPage,
    navigateAdviseStep,
    setCurrentPage,
    showFindMyCarDrawer,
    setShowFindMyCarDrawer,
  } = useFindMyCarStore();

  const handleClose = () => {
    setTimeout(() => {
      setShowFindMyCarDrawer(false);
    }, 500);
  };

  const label = useMemo(() => {
    const key = currentPage !== FindMyCarPageEnum.Landing ? `${currentPage}.title` : "title";
    return t(`FindMyCar.${key}`);
  }, [currentPage, t]);

  const labelSx = useMemo(() => {
    return {
      fontWeight: { xs: 600, sm: 700 },
      fontSize: { xs: "16px", sm: "20px", lg: "24px" },
      lineHeight: { xs: "30px", sm: "34px" },
      background: color.redGradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    } as SxProps;
  }, []);

  const handleClickBack = useCallback(() => {
    if (
      currentPage === FindMyCarPageEnum.Advise &&
      currentAdviseStep !== FindMyCarAdviseStepEnum.Region
    ) {
      navigateAdviseStep("back");
    } else {
      setCurrentPage(FindMyCarPageEnum.Landing);
    }
  }, [currentAdviseStep, currentPage, navigateAdviseStep, setCurrentPage]);

  return (
    <>
      {Boolean(!isLessThanSm && showFindMyCarDrawer) && (
        <ModalCloseOutBoxCustom
          name="find-my-car"
          open={Boolean(!isLessThanSm && showFindMyCarDrawer)}
          onClose={handleClose}
          dialogProps={{
            sx: {
              "& .MuiPaper-root": {
                bgcolor: "transparent",
                margin: "8px",
                boxShadow: "unset",
                width: { sm: "580px", lg: "840px" },
                maxWidth: { sm: "580px", lg: "840px" },
              },
              "& .MuiModal-backdrop": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            },
          }}
          dialogContentProps={{
            sx: {
              "&::-webkit-scrollbar": {
                display: "none !important",
              },
            },
          }}
        >
          <Box component={"div"} sx={{ width: "100%", padding: { md: "30px" } }}>
            <Box
              component={"div"}
              sx={{
                width: "100%",
                bgcolor: color.white,
                borderRadius: "1rem",
                display: "flex",
                flexDirection: "column",
                padding: { sm: "32px 24px" },
                gap: 2,
              }}
            >
              <Stack gap={2}>
                <Toolbar
                  variant="dense"
                  sx={{
                    minHeight: 30,
                    justifyContent: "center",
                    paddingX: { xs: 2, sm: 0 },
                  }}
                  disableGutters
                >
                  <Typography sx={labelSx}>{label}</Typography>
                </Toolbar>
                <DividerCustom sx={{ borderColor: color.gray170 }} />
              </Stack>
              <FindMyCarContent handleClose={handleClose} />
            </Box>
          </Box>
        </ModalCloseOutBoxCustom>
      )}
      {Boolean(isLessThanSm && showFindMyCarDrawer) && (
        <DrawerBottomCustom
          labelName={label}
          labelSx={labelSx}
          isOpen={Boolean(isLessThanSm && showFindMyCarDrawer)}
          onCloseDrawer={handleClose}
          showBackButton={currentPage !== FindMyCarPageEnum.Landing}
          onClickBack={handleClickBack}
        >
          <DividerCustom sx={{ borderColor: color.gray170 }} />
          <FindMyCarContent handleClose={handleClose} />
        </DrawerBottomCustom>
      )}
    </>
  );
};

export default FindMyCarDrawer;
