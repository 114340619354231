import * as yup from "yup";

const htmlTagPattern = /<\/?[^>]+(>|$)/g;
const scriptTagPattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
const todayNow = new Date();

export const contactSchema = yup.object().shape({
  CustomerName: yup.string()
  .test(
    'no-html-or-script',
    "common.droplead.error.html-script",
    (value) => {
      if (!value) return true;  // Skip validation if input is empty
      return !htmlTagPattern.test(value) && !scriptTagPattern.test(value);
    })
    .required("Home.find-my-car.errors.name-require"),
  PhoneNumber: yup
    .string()
    .required("Home.find-my-car.errors.phone-require")
    .transform((value) => value.replace(/-/g, ""))
    .matches(/^0\d{9}$/, "Home.find-my-car.errors.phone-format"),
  Brand: yup.number().required("Home.find-my-car.errors.brand-require"),
  Model: yup.number().required("Home.find-my-car.errors.model-require"),
  Color: yup.number().optional(),
  Grade: yup.number().optional(),
  Province: yup.number().required("Home.find-my-car.errors.province-require"),
  Dealer: yup.number().required("Home.find-my-car.errors.dealer-require"),
  Branch: yup.number().required("Home.find-my-car.errors.branch-require"),
  ContactBack: yup.date()
    .min(todayNow, "common.droplead.error.date-time")
    .required("Home.find-my-car.errors.contact-back-require"),
  ContactType: yup.string().optional(),
});
