import { FC, ReactNode } from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import color from "@/styles/color";
import IconLoader from "../IconLoader";

type ButtonSelectItemProps = ButtonProps & {
  label: ReactNode;
  isSelected: boolean;
};

const ButtonSelectItem: FC<ButtonSelectItemProps> = ({ label, isSelected, sx, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{
        width: "100%",
        height: { xs: "40px", xl: "52px" },
        borderRadius: "8px",
        // padding: "16px 24px",
        paddingX: 0,
        background: `${isSelected ? color.redGradient : color.gray650} !important`,
        boxShadow: "unset",
        gap: "6px",
        justifyContent: "center",
        alignItems: "center",
        ":hover": {
          background: "inherit",
          boxShadow: "unset",
          opacity: 0.9,
        },
        ...sx,
      }}
      {...rest}
      disableRipple
      disableTouchRipple
    >
      {isSelected && (
        <IconLoader
          iconName="CheckCircleIcon"
          sx={{
            "--color-stop-3": color.white,
            "--color-stop-4": color.white,
          }}
        />
      )}
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: "14px", xl: "20px" },
          lineHeight: "24px",
          color: isSelected ? color.white : color.gray400,
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonSelectItem;
