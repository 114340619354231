import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { IGetMasterDataRequest, TMasterDataRep, TMasterDataReq } from "./type";
import {
  mockBrand,
  mockCarType,
  mockGrade,
  mockModel,
  mockPromotionBanner,
  mockProvince,
} from "@/libs/mocks/master-data-filter.mock";
import { apiPaths } from "@/libs/constant/api";
import { MasterDataResponse } from "@/libs/types/master-data.type";
import { MasterBrandDataResponse } from "@/libs/types/smart-search.type";

export const MasterServices = {
  getMasterDataV1: async (params: IGetMasterDataRequest) => {
    // const res: IGetMasterDataResponse = await axiosDSureInstance
    //   .get("/api/master", { params })
    //   .then((response) => response.data);

    if (params.keyword === "carbrand") {
      return {
        result: mockBrand,
      };
    } else if (params.keyword === "carmodel") {
      return {
        result: mockModel,
      };
    } else if (params.keyword === "promotion") {
      return {
        result: mockPromotionBanner,
      };
    } else if (params.keyword === "cartype") {
      return {
        result: mockCarType,
      };
    } else if (params.keyword === "grade") {
      return {
        result: mockGrade,
      };
    } else if (params.keyword === "province") {
      return {
        result: mockProvince,
      };
    }
    // return res;
  },
  getMasterData: async (params: TMasterDataReq): Promise<MasterDataResponse> => {
    const res: TMasterDataRep = await axiosDSureInstance
      .get(apiPaths.master.filter, { params })
      .then((response) => response.data);
    return res;
  },
  getMasterBrandSearchData: async (params: TMasterDataReq): Promise<MasterBrandDataResponse> => {
    const res = await axiosDSureInstance
      .get(apiPaths.master.filter, { params })
      .then((response) => response.data);
    return res;
  },
};
