import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";

import ButtonSelectItem from "@/components/Button/ButtonSelectItem";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { TMasterDataItem } from "@/libs/services/master/type";
import { MasterDataSearchKeyWordEnum } from "@/libs/enum/master-search-key.enum";
import { MasterServices } from "@/libs/services/master/master.service";

type StepRegionProps = {
  regionList: TMasterDataItem[];
};

const StepRegion: FC<StepRegionProps> = ({ regionList }) => {
  const { adviseFilter, setAdviseFilter } = useFindMyCarStore();

  const handleSelectBangkok = useCallback(async (regionId: number) => {
    const response = await MasterServices.getMasterData({
      SearchKeyword: MasterDataSearchKeyWordEnum.PROVINCE,
      Region: regionId,
    }).then((res) => res.SearchFilter);
    const provinceList = response?.filter((item) => !item.Name.toLowerCase().includes("all"));
    setAdviseFilter({ ...adviseFilter, region: regionId, Province: provinceList[0] });
  }, []);

  const handleClickSelectRegion = useCallback(
    (regionId: number, isBangkok: boolean) => {
      setAdviseFilter({ ...adviseFilter, region: regionId });
      useFindMyCarStore.setState({ isRegionBangkok: isBangkok });
      if (isBangkok) {
        handleSelectBangkok(regionId);
      }
    },
    [adviseFilter, setAdviseFilter],
  );

  const regionListResult = useMemo(() => {
    return regionList;
  }, [regionList]);

  return (
    <Stack
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        "& > *": {
          flex: "0 calc(50% - 8px)",
        },
      }}
    >
      {regionListResult.map((region) => {
        const { Id, Name } = region;
        const isSelected = adviseFilter?.region === Id;
        return (
          <ButtonSelectItem
            key={`button-select-region-${Id}`}
            label={Name}
            isSelected={isSelected}
            onClick={() => handleClickSelectRegion(Id, Name === "กรุงเทพและปริมณฑล")}
          />
        );
      })}
    </Stack>
  );
};

export default StepRegion;
