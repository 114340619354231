import { MasterServices } from "@/libs/services/master/master.service";
import { MasterData, MasterDataParams } from "@/libs/types/master-data.type";
import { useQuery } from "@tanstack/react-query";

const useGetMasterData = ({ SearchKeyword }: MasterDataParams) => {
  const fetchMasterData = async (params: MasterDataParams) => {
    const response = await MasterServices.getMasterData(params);
    return response.SearchFilter;
  };

  return useQuery<MasterData[]>({
    queryKey: [SearchKeyword],
    queryFn: () => fetchMasterData({ SearchKeyword }),
  });
};

export default useGetMasterData;
