import { useCallback } from "react";
import { Trans } from "react-i18next";
import { Stack } from "@mui/material";

import ButtonSelectItem from "@/components/Button/ButtonSelectItem";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { thousandComma } from "@/libs/common/string";
import { adviseFilterBudgetRange } from "@/libs/constant/find-my-car.constant";

const StepBudget = () => {
  const { adviseFilter, setAdviseFilter } = useFindMyCarStore();

  const handleClickSelectBudgetRange = useCallback(
    (value: string) => {
      const budget = adviseFilterBudgetRange.find((budget) => budget.key === value);
      setAdviseFilter({ ...adviseFilter, budget: value, Price: budget!.value });
    },
    [adviseFilter, setAdviseFilter],
  );

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      {adviseFilterBudgetRange.map((budget, index) => {
        const { key, translation } = budget;
        const { key: translationKey, value: translationValue } = translation;
        const isSelected = adviseFilter?.budget === key;
        return (
          <ButtonSelectItem
            key={`button-select-budget-${index}`}
            label={
              <Trans
                i18nKey={`common.price.${translationKey}`}
                min={translationValue.min}
                max={translationValue.max}
              >
                {{ min: thousandComma(translationValue.min) }}
                {{ max: thousandComma(translationValue.max ?? 0) }}
              </Trans>
            }
            isSelected={isSelected}
            onClick={() => handleClickSelectBudgetRange(key)}
          />
        );
      })}
    </Stack>
  );
};

export default StepBudget;
