import IconLoader from "@/components/IconLoader";
import color from "@/styles/color";
import { SxProps, TextField, TextFieldProps } from "@mui/material";
import React, { forwardRef } from "react";

export interface ITextInputSearchCustomProps {
  borderColor?: string;
  borderRadius?: string;
  textAlign?: string;
  showIcon?: boolean;
  iconSx?: SxProps;
}

const TextSearchInput = forwardRef(
  (
    {
      borderColor,
      borderRadius,
      textAlign,
      showIcon = true,
      iconSx = {},
      ...rest
    }: TextFieldProps & ITextInputSearchCustomProps,
    ref,
  ) => {
    return (
      <TextField
        {...rest}
        inputRef={ref}
        fullWidth
        variant="outlined"
        autoComplete="off"
        InputProps={{
          sx: {
            borderRadius: borderRadius || "8px !important",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "22px",
            color: color.black,
            background: color.gray120,
            textAlign: textAlign || "",
            height: "40px",
            width: "100%",
            padding: "16px 24px",
          },
          ...rest?.InputProps,
          startAdornment: showIcon ? (
            <IconLoader
              iconName="SearchBoldIcon"
              sx={{ fontSize: "18px", color: color.gray400, marginRight: "8px", ...iconSx }}
            />
          ) : null,
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            borderWidth: "0px !important",
          },
          "& .Mui-focused": {
            // "& > svg": {
            //   color: "#000000 !important",
            // },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "0px !important",
            },
          },
          "& .Mui-disabled": {
            color: "#979797 !important",
            WebkitTextFillColor: "#979797 !important",
            bgcolor: "#FFFFFF !important",
          },
          "& input::placeholder": {
            opacity: 1,
            color: color.grey,
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "21px",
          },
          "& .MuiInputBase-input": {
            ":-webkit-autofill": {
              height: "19px",
              bgcolor: "transparent",
            },
          },
          ...rest?.sx,
        }}
        SelectProps={{
          sx: {
            borderRadius: borderRadius || "28px",
            fontWeight: 500,
            color: "#191919",
            background: "#F7FBFF",
            borderColor: borderColor || "#E2E2E2",
            textAlign: textAlign || "",

            "& .Mui-disabled": {
              color: "#979797 !important",
              WebkitTextFillColor: "#979797 !important",
              bgcolor: "#FFFFFF !important",
            },
          },
        }}
        InputLabelProps={{
          shrink: true,
          sx: {
            "&.Mui-disabled": {
              color: "#979797 !important",
            },
          },
        }}
      />
    );
  },
);

TextSearchInput.displayName = "TextSearchInput";

export default TextSearchInput;
