import { FC, useCallback, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";

import ButtonSelectItem from "@/components/Button/ButtonSelectItem";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { TMasterDataItem } from "@/libs/services/master/type";
import TextSearchInput from "@/components/Input/TextSearchInput/TextSearchInput";
import color from "@/styles/color";
import { MasterData } from "@/libs/types/master-data.type";

const NoContentComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No data
    </Box>
  );
};

type StepProvinceProps = {
  provinceList: TMasterDataItem[];
};

const StepProvince: FC<StepProvinceProps> = ({ provinceList }) => {
  const { adviseFilter, setAdviseFilter } = useFindMyCarStore();
  const [provinceSearchText, setProvinceSearchText] = useState<string>("");

  const handleClickSelectProvince = useCallback(
    (province: MasterData) => {
      setAdviseFilter({ ...adviseFilter, Province: province });
    },
    [adviseFilter, setAdviseFilter],
  );

  const provinceListResult = useMemo(() => {
    const itemList = provinceList.filter((province) => province.Id) ?? [];
    return provinceSearchText === ""
      ? itemList
      : itemList?.filter((province) => province.Name.toLowerCase().includes(provinceSearchText));
  }, [provinceList, provinceSearchText]);

  return (
    <Stack
      sx={{
        gap: 2,
        height: provinceListResult.length === 0 ? "100%" : "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          background: color.white,
          position: "sticky",
          top: 0,
          zIndex: 1,
          paddingRight: { xs: '8px', md: '20px' }
        }}
      >
        <TextSearchInput
          borderRadius="30px"
          placeholder="ค้นหาจังหวัด"
          InputProps={{
            onChange: (e) => {
              setProvinceSearchText(e.currentTarget.value);
            },
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${color.grey} !important`,
            },
            "& .Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "1px !important",
              },
            },
            "& .MuiInputBase-root": {
              background: color.white,
            },
          }}
        />
      </Box>
      <Box
        sx={{
          height: { xs: "208px" },
          maxHeight: { xs: "208px" },
          overflowY: "auto",
          paddingRight: { xs: '8px', md: '16px' }
        }}
      >
        <Stack
          sx={{
            gap: 2,
            height: provinceListResult.length === 0 ? "100%" : "auto",
            position: "relative",
          }}
        >
          {provinceListResult.length > 0 ? (
            provinceListResult.map((province) => {
              const { Id, Name } = province;
              const isSelected = adviseFilter?.Province?.Id === Id;
              return (
                <ButtonSelectItem
                  key={`button-select-Province-${Id}`}
                  label={Name}
                  isSelected={isSelected}
                  onClick={() => handleClickSelectProvince(province)}
                />
              );
            })
          ) : (
            <NoContentComponent />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default StepProvince;
