import { FC } from "react";
import SearchCarsSelectCustom from "@/components/SearchCars/SearchCarsSelectCustom";
import { UseFormRegisterReturn } from "react-hook-form";
import color from "@/styles/color";
import { useTranslation } from "@/i18n/client";
import { SxProps, Theme } from "@mui/material";

type SelectOptions = {
  label: string;
  value: string | number;
};

type SelectItemOptionProps = {
  label: string;
  nameKey: string;
  options: SelectOptions[];
  registerForm?: UseFormRegisterReturn<string>;
  formControlSx: SxProps;
  isRequired?: boolean;
  disabled?: boolean;
  labelSx?: SxProps<Theme>;
};

const SelectItemOption: FC<SelectItemOptionProps> = ({
  label,
  options,
  nameKey,
  registerForm,
  formControlSx,
  isRequired = false,
  disabled = false,
  labelSx = {
    fontSize: { xs: "11px", sm: "12px" },
    fontWeight: 400,
  },
}) => {
  const { t } = useTranslation();

  return (
    <SearchCarsSelectCustom
      label={label}
      labelSx={labelSx}
      options={options}
      dataKey={nameKey}
      registerForm={registerForm}
      sx={{
        marginTop: "0px",
        "& .MuiOutlinedInput-root": {
          padding: "0px 12px !important",
        },
      }}
      formControlSx={formControlSx}
      inputSx={{
        backgroundColor: color.gray280,
        borderRadius: { xs: "8px" },
        fontSize: { xs: "14px", xl: "16px" },
        fontWeight: 500,
        height: { xs: "44px", xl: "56px" },
        padding: { xs: "inherit", xl: "0px 16px !important" },
        "& .MuiAutocomplete-input": {
          flexGrow: { xs: 1, xl: "unset" },
          padding: { xs: "inherit", xl: "16px 0px !important" },
          minWidth: { xs: "inherit", xl: "100px !important" },
          width: "100% !important",
        },
        "& .MuiOutlinedInput-root": {
          padding: "0px 12px!important",
        },
      }}
      showLabel
      disabled={disabled}
      isRequired={isRequired}
      placeholder={t("common.form.please-input")}
    />
  );
};

export default SelectItemOption;
