import { FC, useCallback, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";

import ButtonSelectItem from "@/components/Button/ButtonSelectItem";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { useTranslation } from "@/i18n/client";
import { TMasterDataItem } from "@/libs/services/master/type";
import color from "@/styles/color";
import { MasterData } from "@/libs/types/master-data.type";
import { FindMyCarAdviseFilter } from "@/libs/types/find-my-car.type";

type StoreKey = "CarType" | "FuelType" | "CarCertified"

type StepCarTypeProps = {
  carTypeList: TMasterDataItem[];
  carFuelTypeList: TMasterDataItem[];
};

const appendCertificate = () => {
  return [
    {
      Id: 1,
      Name: "IsCertified",
      NameEn: "IsCertified",
      ImageFileURL: "",
      ImageFileUrl: "",
      Count: 0,
      Order: 0,
      Lattitude: "",
      Longitude: ""
    }
  ]
}

const StepCarType: FC<StepCarTypeProps> = ({ carFuelTypeList, carTypeList }) => {
  const { t } = useTranslation();
  const { adviseFilter, setAdviseFilter } = useFindMyCarStore();

  const sectionList = useMemo(() => {
    return [
      {
        key: "CarType",
        data: carTypeList,
        translationKey: "choose-car-type",
      },
      {
        key: "FuelType",
        data: carFuelTypeList,
        translationKey: "choose-fuel-type",
      },
      {
        key: "CarCertified",
        data: appendCertificate(),
        translationKey: "choose-car-certified",
      }
    ];
  }, [carTypeList, carFuelTypeList]);

  const handleClickSelectItem = useCallback(
    (item: MasterData, storeKey: string) => {
      const data = adviseFilter[storeKey as StoreKey] ?? [];
      if (data.Name === 'IsCertified') {
        const remove = Object.entries(adviseFilter)
          .filter(([key, _]) => key !== 'CarCertified')
          .reduce<FindMyCarAdviseFilter>((prev, [key, obj]) => ({
            ...prev,
            [key]: obj
          }), {} as unknown as FindMyCarAdviseFilter)

        setAdviseFilter(remove);
      } else {
        setAdviseFilter({ ...adviseFilter, [storeKey]: item });
      }
    },
    [adviseFilter, setAdviseFilter],
  );

  return (
    <Stack
      sx={{
        gap: 3,
      }}
    >
      {sectionList.map(({ key, data, translationKey }) => {
        const coloumLg = key === "FuelType" ? 3 : 5;
        return (
          <Stack
            key={`section-${key}`}
            sx={{
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { xs: "0.875rem", lg: "1.25rem" },
                color: color.gray400,
                textAlign: { xs: "center" },
              }}
            >
              {t(`FindMyCar.advise.step.${translationKey}`)}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: {
                  xs: "repeat(2, minmax(0, 1fr))",
                  sm: "repeat(3, minmax(0, 1fr))",
                  lg: `repeat(${coloumLg}, minmax(0, 1fr))`,
                },
              }}
            >
              {data?.map((item, index) => {
                const isCertified = item.Name.includes("IsCertified")
                const isSelected = adviseFilter[key as StoreKey]?.Id === item.Id;
                const label = item.Name.toLowerCase().includes("all") ? t("common.all") : isCertified ? t("common.certified") : item.Name;
                return (
                  <ButtonSelectItem
                    key={`button-select-${key}-${index}`}
                    label={label}
                    isSelected={isSelected}
                    onClick={() => handleClickSelectItem(item, key)}
                  />
                );
              })}
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default StepCarType;
