import { useCallback, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Grow from "@mui/material/Grow";
import { useTranslation } from "@/i18n/client";

import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { FindMyCarPageEnum, FindMyCarQueryEnum } from "@/libs/enum/find-my-car.enum";
import color from "@/styles/color";
import RHFFormProvider from "@/libs/react-hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { FindMyCarContactFormSchema } from "@/libs/types/find-my-car.type";
import FindMyCarContactForm from "./FindMyCarContactForm";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { FindMyCarService } from "@/libs/services/find-my-car/find-my-car.service";
import ModalContactSuccess from "./modal/ModalContactSuccess";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactSchema } from "@/modules/authentication/components/forms/schema/contact";
import { FindMyCarContactRequest } from "@/libs/types/find-my-car.type";
import * as Sentry from "@sentry/nextjs";

interface FindMyCarContactType {
  handleClose: () => void;
}

const FindMyCarContact = ({ handleClose }: FindMyCarContactType) => {
  const { t } = useTranslation();
  const { setCurrentPage } = useFindMyCarStore();
  const [modalAlert, setModalAlert] = useState<boolean>(false);
  const showModal = () => setModalAlert(true);
  const closeModal = () => setModalAlert(false);

  const contactForm = useForm<FindMyCarContactFormSchema>({
    resolver: yupResolver(contactSchema),
  });
  const [customerContactParams, setCustomerContactParams] = useState<FindMyCarContactRequest>();

  useQuery<FindMyCarContactFormSchema>({
    queryKey: [FindMyCarQueryEnum.CUSTOMER_CONTACT],
    queryFn: () =>
      FindMyCarService.postCustomerContact(
        customerContactParams!,
        handleClose,
        showModal,
        closeModal,
      ),
    enabled: !!customerContactParams,
  });

  const handleClickBack = useCallback(() => {
    setCurrentPage(FindMyCarPageEnum.Landing);
  }, [setCurrentPage]);

  const handleSubmitForm = contactForm.handleSubmit(async (data) => {
    try {
      const payload = {
        ...data,
        ContactType: "CustomerContact",
        ContactBack: dayjs(data.ContactBack).format("YYYY-MM-DD HH:mm:ss"),
      };
      setCustomerContactParams(payload);
      // capture payload to Sentry
      const scope = new Sentry.Scope();
      scope.setTag("section", "droplead");
      Sentry.captureMessage(JSON.stringify(payload), scope);
    } catch (error) {
      console.log("handleSubmitForm error => ", error);
    }
  });

  return (
    <Grow in unmountOnExit>
      <Stack
        sx={{
          gap: { xs: 2 },
        }}
      >
        <Typography
          component={"h2"}
          sx={{
            color: color.gray400,
            fontSize: { xs: "0.625rem", sm: "0.75rem", xl: "0.975rem" },
            fontWeight: { xs: 500, sm: 700 },
            lineHeight: { xs: "1rem", sm: "2.125rem" },
            textAlign: { xs: "left", sm: "center" },
          }}
        >
          {t("FindMyCar.contact.caption")}
        </Typography>
        <RHFFormProvider methods={contactForm} onSubmit={handleSubmitForm}>
          <Stack
            sx={{
              gap: { xs: 2 },
            }}
          >
            <FindMyCarContactForm form={contactForm} onClickBack={handleClickBack} />
          </Stack>
        </RHFFormProvider>
        <ModalContactSuccess visible={modalAlert} message={t("common.modal.message-success")} />
      </Stack>
    </Grow>
  );
};

export default FindMyCarContact;
