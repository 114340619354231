import { useMemo } from "react";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import Grow from "@mui/material/Grow";
import { useTranslation } from "@/i18n/client";

import ButtonCustom from "@/components/Button/ButtonCustom";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { FindMyCarPageEnum } from "@/libs/enum/find-my-car.enum";
import color from "@/styles/color";
import { DividerCustom } from "@/components/Divider/DividerCustom";

import FindMyCarImg from "@/assets/image/find-my-car.png";
import Image from "next/image";

const FindMyCarLanding = () => {
  const { t } = useTranslation();
  const { setCurrentPage } = useFindMyCarStore();

  const buttonStyleSx: SxProps = {
    height: { xs: "40px", md: "64px" },
    fontSize: { xs: "0.875rem", md: "1.25rem" },
    lineHeight: { xs: "1.3125rem", md: "1.4375rem" },
    padding: 0,
    width: "100%",
    color: color.formField,
  };

  const buttonList = useMemo(() => {
    return [
      {
        key: FindMyCarPageEnum.Advise,
      },
      {
        key: FindMyCarPageEnum.Contact,
      },
    ];
  }, []);

  return (
    <Grow in unmountOnExit>
      <Stack
        sx={{
          gap: { xs: 2 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 3, sm: 2 },
          }}
        >
          <Typography
            sx={{
              color: color.gray400,
              fontWeight: 600,
              fontSize: { xs: "16px", md: "18px", xl: "24px" },
              lineHeight: { xs: "30px", md: "24px", xl: "36px" },
            }}
          >
            {t("FindMyCar.description")}
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: {
                xs: "250px",
                xl: "328px",
              },
              height: "auto",
            }}
          >
            <Image
              src={FindMyCarImg.src}
              alt="Find My Car"
              width={0}
              height={0}
              sizes="100%"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
                objectFit: "cover",
              }}
              priority={true}
            />
          </Box>
        </Box>
        <DividerCustom sx={{ borderColor: color.gray170 }} />
        <Stack
          sx={{
            flexDirection: { sm: "row" },
            gap: 2,
          }}
        >
          {buttonList.map(({ key }) => {
            return (
              <ButtonCustom
                key={`button-${key}`}
                onClick={() => setCurrentPage(key)}
                label={t(`FindMyCar.${key}.title`)}
                sx={{
                  ...buttonStyleSx,
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </Grow>
  );
};

export default FindMyCarLanding;
