import { Controller, useFormContext, UseFormRegisterReturn } from "react-hook-form";

import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Popper,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import color from "@/styles/color";
import { useTranslation } from "@/i18n/client";
import IconLoader from "../IconLoader";

export interface ISelectOptions {
  label: string;
  value: string | number;
}

interface SearchCarsSelectCustomProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, "renderInput"> {
  showLabel?: boolean;
  isRequired?: boolean;
  label: string;
  labelSx?: SxProps<Theme>;
  dataKey: string;
  autoCompleteId?: string;
  customOnChange?: (event: React.SyntheticEvent, value: ISelectOptions | null) => void;
  renderSx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  formControlSx?: SxProps<Theme>;
  registerForm?: UseFormRegisterReturn<string>;
  placeholder?: string;
  disableTooltips?: boolean;
  onBlur?: () => void;
}

const SearchCarsSelectCustom = ({
  showLabel = false,
  isRequired = false,
  disableTooltips = true,
  label,
  labelSx,
  autoCompleteId,
  dataKey,
  customOnChange,
  renderSx = {},
  inputSx = {},
  formControlSx = {},
  registerForm,
  placeholder,
  onClick,
  onBlur,
  ...autocompleteProps
}: SearchCarsSelectCustomProps<ISelectOptions>) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={dataKey}
      control={control}
      defaultValue={null}
      rules={{ required: isRequired }}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ width: "100%", ...formControlSx }}>
          {showLabel && (
            <FormLabel
              sx={{
                ...labelSx,
                lineHeight: { xs: "16.5px", sm: "18px" },
                color: color.gray400,
                "&.Mui-focused": {
                  color: color.gray400,
                },
              }}
              htmlFor={dataKey}
            >
              {label}
              {isRequired && <span style={{ color: color.red }}>*</span>}
            </FormLabel>
          )}
          <Autocomplete
            {...autocompleteProps}
            noOptionsText={<Skeleton variant="text" sx={{ fontSize: "18px" }} />}
            id={autoCompleteId ? autoCompleteId : dataKey}
            // isOptionEqualToValue={(option, value) => option.value === field.value}
            sx={{
              padding: "0px",
              "& .MuiOutlinedInput-root": {
                padding: { xs: "0px 12px !important", sm: "0px 4px !important" }, // xl: "unset !important" },
              },
              "& .MuiAutocomplete-hasClearIcon": {
                padding: "unset !important",
              },
              ...autocompleteProps?.sx,
            }}
            PaperComponent={(props) => (
              <Box
                {...props}
                visibility={"visible"}
                sx={{
                  "&.MuiAutocomplete-listbox": { padding: "0px !important" },
                  wordBreak: "break-word",
                  borderRadius: "16px",
                  background: color.white,
                  padding: "0px !important",
                  width: "auto",
                }}
              ></Box>
            )}
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  width: { xs: "auto", sm: "250px !important" },
                  marginTop: "200px",
                  "& .MuiAutocomplete-listbox": {
                    margin: 0,
                    padding: "0px !important",
                    maxHeight: { xs: "200px", sm: "250px" },
                    boxShadow: "0px 4px 4px 0px #0000001A",
                  },
                  [`.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]`]: {
                    background: color.redGradient,
                    color: color.white,
                  },
                  padding: "0px !important",
                }}
              ></Popper>
            )}
            renderOption={(props, option, { index }) => (
              <div key={index}>
                <MenuItem
                  {...props}
                  key={option?.value}
                  sx={{
                    padding: "11px 24px !important",
                    overflow: "wrap",
                    whiteSpace: "pre-wrap",
                    color: color.black,
                    fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                  }}
                >
                  {option?.label}
                </MenuItem>
                {index !== autocompleteProps.options.length - 1 && (
                  <Divider
                    sx={{
                      "&.MuiDivider-root": {
                        marginTop: "0px !important",
                        marginBottom: "0px !important",
                      },
                      color: color.gray500,
                      margin: "0px",
                    }}
                  />
                )}
              </div>
            )}
            popupIcon={<IconLoader iconName="ArrowDownIcon" sx={{ fontSize: "14px" }} />}
            renderInput={(params) => (
              <Tooltip
                disableFocusListener={disableTooltips}
                disableHoverListener={disableTooltips}
                disableInteractive={disableTooltips}
                disableTouchListener={disableTooltips}
                title={
                  <Box>
                    <Box style={{ fontSize: "14px" }}>
                      {field.value
                        ? autocompleteProps.options.find((option) => option.value === field.value)
                            ?.label
                        : label}
                    </Box>
                  </Box>
                }
                placement="right-end"
                arrow
                sx={{ fontSize: 150, display: "none" }}
              >
                <TextField
                  {...params}
                  size="medium"
                  placeholder={placeholder ?? label}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 600,
                      padding: "0px !important",
                      fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                      ...inputSx,
                    },
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                    fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                    "& input::placeholder": {
                      fontSize: { xs: "12px", sm: "14px", xl: "18px" },
                    },
                    "& fieldset": { border: "none", position: "unset", padding: "unset" },
                    "& .MuiOutlinedInput-input": {
                      padding: "unset",
                    },
                    "& .MuiAutocomplete-input": {
                      flexGrow: "unset",
                      padding: { xs: "0px 12px", xl: "unset" },
                    },
                    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                      padding: "unset",
                    },
                    // "& .MuiAutocomplete-endAdornment": {
                    //   position: "unset",
                    //   transform: "unset",
                    // },
                    ...renderSx,
                  }}
                  helperText={t(error?.message as string)}
                  error={!!error}
                  onClick={onClick}
                  onBlur={() => onBlur && onBlur()}
                />
              </Tooltip>
            )}
            {...field}
            value={
              autocompleteProps?.options?.find((option) => option.value === field.value) || null
            }
            onChange={(event, value) => {
              if (customOnChange) {
                customOnChange(event, value);
              }
              value !== null ? field.onChange(value?.value) : field.onChange(value);
            }}
            clearIcon={false}
          ></Autocomplete>
        </FormControl>
      )}
    />
  );
};

export default SearchCarsSelectCustom;
