import { axiosDSureInstance } from "@/libs/axios/axios-dsure-instance";
import { FindMyCarContactRequest } from "@/libs/types/find-my-car.type";

export const FindMyCarService = {
  postCustomerContact: async (
    // params: FindMyCarContactFormSchema,
    params: FindMyCarContactRequest,
    handleClose: () => void,
    showModal: () => void,
    closeModal: () => void,
  ): Promise<any> => {
    const res: string = await axiosDSureInstance
      .post("/TSure_API/rest/InterestedVehicle/SetInterestedVehicleData", {
        CustomerName: params.CustomerName,
        PhoneNumber: params.PhoneNumber,
        ContactBack: params.ContactBack,
        ContactType: "CustomerContact",
        Brand: params.Brand,
        Model: params.Model,
        Grade: params.Grade ? params.Grade : null,
        Color: params.Color ? params.Color : null,
        Dealer: params.Dealer,
        Branch: params.Branch,
        Province: params.Province,
        // CustomerName: "Sam123",
        // PhoneNumber: 12756789,
        // ContactBack: "2024-09-18 09:16:11",
        // ContactType: "CustomerContact",
        // Brand: 5718,
        // Model: 6315,
        // Grade: 2531,
        // Color: null,
        // Dealer: 222,
        // Branch: 286,
        // Province: 299,
      })
      .then((response) => {
        showModal();
        setTimeout(() => {
          closeModal();
          handleClose();
        }, 2500);
        return response.data;
      })
      .catch((error) => console.log(error));
    return {
      SuccessMessage: res,
    };
  },
};
