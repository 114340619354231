import { FindMyCarAdviseStepEnum, FindMyCarPageEnum } from "@/libs/enum/find-my-car.enum";
import { FindMyCarAdviseFilter } from "@/libs/types/find-my-car.type";
import { create } from "zustand";

type FindMyCarStore = {
  currentPage: FindMyCarPageEnum;
  setCurrentPage: (currentPage: FindMyCarPageEnum) => void;

  currentAdviseStep: FindMyCarAdviseStepEnum;
  setCurrentAdviseStep: (currentAdviseStep: FindMyCarAdviseStepEnum) => void;
  navigateAdviseStep: (direction: "next" | "back") => void;

  adviseFilter: FindMyCarAdviseFilter;
  setAdviseFilter: (adviseFilter: FindMyCarAdviseFilter) => void;

  showFindMyCarDrawer: boolean;
  setShowFindMyCarDrawer: (showFindMyCarDrawer: boolean) => void;

  isRegionBangkok: boolean;
};

const carCertified = {
  Id: 1,
  Name: "IsCertified",
  ImageFileURL: "",
  ImageFileUrl: "",
  Count: 0,
  Order: 0,
  Lattitude: "",
  Longitude: ""
}

const initialState = {
  adviseFilter: { CarCertified: carCertified } as FindMyCarAdviseFilter,
  currentPage: FindMyCarPageEnum.Landing,
  currentAdviseStep: FindMyCarAdviseStepEnum.Region,
  showFindMyCarDrawer: false,
  isRegionBangkok: false,
};

const nextStepMap = {
  [FindMyCarAdviseStepEnum.Region]: FindMyCarAdviseStepEnum.Province,
  [FindMyCarAdviseStepEnum.Province]: FindMyCarAdviseStepEnum.Budget,
  [FindMyCarAdviseStepEnum.Budget]: FindMyCarAdviseStepEnum.CarType,
  [FindMyCarAdviseStepEnum.CarType]: null, // last step
};

const prevStepMap = {
  [FindMyCarAdviseStepEnum.Region]: null, // first step
  [FindMyCarAdviseStepEnum.Province]: FindMyCarAdviseStepEnum.Region,
  [FindMyCarAdviseStepEnum.Budget]: FindMyCarAdviseStepEnum.Province,
  [FindMyCarAdviseStepEnum.CarType]: FindMyCarAdviseStepEnum.Budget,
};

export const useFindMyCarStore = create<FindMyCarStore>((set, get) => ({
  isRegionBangkok: initialState.isRegionBangkok,
  currentPage: initialState.currentPage,
  setCurrentPage: (currentPage: FindMyCarPageEnum) => {
    set({
      currentPage,
      ...(currentPage === FindMyCarPageEnum.Advise && {
        adviseFilter: initialState.adviseFilter,
        currentAdviseStep: initialState.currentAdviseStep,
      }),
    });
  },
  currentAdviseStep: initialState.currentAdviseStep,
  setCurrentAdviseStep: (currentAdviseStep: FindMyCarAdviseStepEnum) => {
    set({ currentAdviseStep });
  },
  navigateAdviseStep: (direction) => {
    const { isRegionBangkok, currentAdviseStep } = get();
    let step;
    if (direction === "next") {
      step =
        isRegionBangkok && currentAdviseStep === FindMyCarAdviseStepEnum.Region
          ? FindMyCarAdviseStepEnum.Budget
          : nextStepMap[currentAdviseStep];
    } else if (direction === "back") {
      step =
        isRegionBangkok && currentAdviseStep === FindMyCarAdviseStepEnum.Budget
          ? FindMyCarAdviseStepEnum.Region
          : prevStepMap[currentAdviseStep];
    }
    if (step) {
      set({ currentAdviseStep: step });
    }
  },
  adviseFilter: initialState.adviseFilter,
  setAdviseFilter: (adviseFilter: FindMyCarAdviseFilter) => {
    set({ adviseFilter });
  },
  showFindMyCarDrawer: initialState.showFindMyCarDrawer,
  setShowFindMyCarDrawer: (showFindMyCarDrawer: boolean) => {
    set((state) => {
      return {
        showFindMyCarDrawer,
        currentPage: showFindMyCarDrawer ? initialState.currentPage : state.currentPage,
      };
    });
  },
}));
