import { FC, useCallback, useMemo, useState } from "react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { buttonBaseClasses, Stack, SxProps } from "@mui/material";
import {  useQuery } from "@tanstack/react-query";

import FormControlContainer from "@/components/Form/FormControlContainer";
import TextFieldInputCustom from "@/components/Input/TextFieldInputCustom";
import AcceptPrivacyPolicyCheckbox from "@/components/Policy/AcceptPrivacyPolicy";
import { TMasterDataRep } from "@/libs/services/master/type";
import { MasterServices } from "@/libs/services/master/master.service";
import { MasterDataSearchKeyWordEnum } from "@/libs/enum/master-search-key.enum";
import { WantToBuyQueryKeyEnum } from "@/libs/enum/wtb-query-key.enum";
import { FindMyCarContactFormSchema } from "@/libs/types/find-my-car.type";
import { useTranslation } from "@/i18n/client";
import SelectItemOption from "./SelectItemOption";
import color from "@/styles/color";
import {
  DealerMapListResponse,
} from "@/libs/types/dealer.type";
import { DealerServices } from "@/libs/services/dealer";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import ButtonCustom from "@/components/Button/ButtonCustom";
import RHFDateTimePickerField from "@/components/Form/RHFDateTimepicker";

type FindMyCarContactFormProps = {
  form: UseFormReturn<FindMyCarContactFormSchema, any, undefined>;
  onClickBack: () => void;
};

const FindMyCarContactForm: FC<FindMyCarContactFormProps> = ({ form, onClickBack }) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  const buttonStyleSx: SxProps = {
    height: { xs: "40px", md: "64px" },
    fontSize: { xs: "0.875rem", md: "1.25rem" },
    lineHeight: { xs: "1.3125rem", md: "1.4375rem" },
    padding: 0,
    width: "100%",
    color: color.formField,
  };

  const [checkedAcceptPolicy, setCheckedAcceptPolicy] = useState<boolean>(false);

  const { data: getBrandData } = useQuery<TMasterDataRep>({
    queryKey: [WantToBuyQueryKeyEnum.CAR_FILTER_CAR_BRAND],
    queryFn: () =>
      MasterServices.getMasterData({ SearchKeyword: MasterDataSearchKeyWordEnum.BRAND }),
  });

  const { data: getModelData } = useQuery<TMasterDataRep>({
    queryKey: [WantToBuyQueryKeyEnum.CAR_FILTER_CAR_MODEL, form.watch("Brand")],
    queryFn: () =>
      MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.MODEL,
        ...(form.getValues("Brand") ? { Brand: Number(form.getValues("Brand")) } : {}),
      }),
    enabled: Boolean(form.getValues("Brand")),
  });

  const { data: getCarColorData } = useQuery<TMasterDataRep>({
    queryKey: [WantToBuyQueryKeyEnum.CAR_FILTER_COLOR],
    queryFn: () =>
      MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.COLOR,
      }),
  });

  const { data: getCarGradeData } = useQuery<TMasterDataRep>({
    queryKey: [WantToBuyQueryKeyEnum.CAR_FILTER_GRADE, form.watch("Model")],
    queryFn: () => {
      const modelId = form.getValues("Model");
      return MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.GRADE,
        ...(modelId !== null ? { Model: modelId } : {}),
      });
    },
    enabled: Boolean(form.getValues("Model")),
  });

  const { data: getProvinceData } = useQuery<TMasterDataRep>({
    queryKey: [WantToBuyQueryKeyEnum.CAR_FILTER_PROVINCE],
    queryFn: () =>
      MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.DEALERPROVINCE,
      }),
  });

  // const { data: dealerAllData, isPending: isLoadingAllDealerList } = useQuery<DealerListResponse>({
  //   queryKey: [DealerQueryKeyEnum.DEALER_LIST, dealerRequest],
  //   queryFn: () => DealerServices.getDealerList(dealerRequest),
  //   placeholderData: keepPreviousData,
  // });
  const { data: dealerAllData } =
    useQuery<DealerMapListResponse>({
      queryKey: [form.watch("Province")],
      queryFn: () => {
        const provinceId = form.getValues("Province");
        return DealerServices.getDealerList({ ProvinceId: provinceId });
      },
    });

  // const { data: branchAllData, isPending: isLoadingAllBranchList } = useQuery<DealerListResponse>({
  //   queryKey: [form.watch("Dealer")],
  //   queryFn: () => {
  //     const dealerId = form.getValues("Dealer");
  //     return DealerServices.getDealerList({ ...dealerRequest, DealerId: dealerId });
  //   },
  //   placeholderData: keepPreviousData,
  //   enabled: Boolean(form.getValues("Dealer")),
  // });

  const { data: branchAllData } = useQuery<TMasterDataRep>({
    queryKey: [form.watch("Dealer")],
    queryFn: () => {
      const dealerId = form.getValues("Dealer");
      return MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.BRANCH,
        Dealer: dealerId,
      });
    },
  });

  const transformOptionItem = useCallback((response?: TMasterDataRep) => {
    return (
      response?.SearchFilter?.filter((item) => item.Id !== 0)?.map((item) => ({
        label: item.Name,
        value: item.Id,
      })) || []
    );
  }, []);

  const transformOptionItemDealer = useCallback((response?: DealerMapListResponse) => {
    return (
      response?.DealerInfo?.filter((item) => item.BranchId !== 0)?.map((item) => ({
        label: item.DealerDisplayName,
        value: item.DealerId,
      })) || []
    );
  }, []);

  const brandList = useMemo(() => {
    return transformOptionItem(getBrandData);
  }, [getBrandData, transformOptionItem]);

  const modelList = useMemo(() => {
    return transformOptionItem(getModelData);
  }, [getModelData, transformOptionItem]);

  const carColorList = useMemo(() => {
    return transformOptionItem(getCarColorData);
  }, [getCarColorData, transformOptionItem]);

  const carGradeList = useMemo(() => {
    return transformOptionItem(getCarGradeData);
  }, [getCarGradeData, transformOptionItem]);

  const provinceList = useMemo(() => {
    return transformOptionItem(getProvinceData);
  }, [getProvinceData, transformOptionItem]);

  const dealerList = useMemo(() => {
    return transformOptionItemDealer(dealerAllData);
  }, [dealerAllData, transformOptionItemDealer]);

  const branchList = useMemo(() => {
    return transformOptionItem(branchAllData);
  }, [branchAllData, transformOptionItem]);

  // const branchList = useMemo(() => {
  //   return transformOptionItemBranch(branchAllData);
  // }, [branchAllData, transformOptionItemBranch]);

  // const dealerList = useMemo(() => {
  //   return dealerAllData
  //     ? dealerAllData?.DealerInfo?.map((dealer) => ({
  //       label: dealer.DealerDisplayName,
  //       value: dealer.DealerId,
  //     }))
  //     : [];
  // }, [dealerAllData?.DealerInfo]);

  // const uniqueDealerList = useMemo(() => {
  //   return dealerAllData
  //     ? Array.from(new Set(dealerAllData?.DealerInfo?.map((dealer) => dealer.DealerId)))
  //         .map((id) => {
  //           return dealerAllData.DealerInfo.find((dealer) => dealer.DealerId === id);
  //         })
  //         .map((dealer) => ({
  //           label: dealer?.DealerDisplayName as string,
  //           value: dealer?.DealerId as number,
  //         }))
  //     : [];
  // }, [dealerAllData?.DealerInfo]);

  return (
    <Stack
      gap={2}
      sx={{
        background: color.white,
      }}
    >
      <Stack gap={2} flexDirection="row">
        <Controller
          name="CustomerName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControlContainer label={t("FindMyCar.contact.form.name")} isRequired>
              <TextFieldInputCustom
                InputProps={{
                  sx: {
                    fontSize: { xs: "14px", xl: "16px" },
                    height: { xs: "44px", xl: "56px" },
                  },
                }}
                placeholder={t("common.form.please-input")}
                helperText={t(error?.message as string)}
                error={!!error}
                {...field}
              />
            </FormControlContainer>
          )}
        />
        <Controller
          name="PhoneNumber"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControlContainer label={t("FindMyCar.contact.form.phone")} isRequired>
              <TextFieldInputCustom
                {...field}
                placeholder={t("common.form.please-input")}
                error={!!error}
                helperText={t(error?.message as string)}
                onChange={(e) => {
                  const formattedValue = e.target.value
                    .replace(/[^\d]/g, "")
                    .replace(/(\d{3})(\d)/, "$1-$2")
                    .replace(/(\d{3})(\d)/, "$1-$2")
                    .slice(0, 12);

                  field.onChange(formattedValue);
                }}
                inputProps={{
                  sx: {
                    fontSize: { xs: "14px", xl: "16px" },
                    height: { xs: "44px", xl: "56px" },
                  },
                }}
              />
            </FormControlContainer>
          )}
        />
      </Stack>
      <Stack gap={2} flexDirection="row" flexWrap="wrap">
        <SelectItemOption
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 3) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
            paddingx: "8px",
          }}
          label={t("FindMyCar.contact.form.brand")}
          nameKey="Brand"
          options={brandList}
          isRequired={true}
          registerForm={form.register("Brand")}
        />
        <SelectItemOption
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 3) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.model")}
          nameKey="Model"
          options={modelList}
          disabled={!Boolean(form.getValues("Brand"))}
          isRequired={true}
          registerForm={form.register("Model")}
        />
        <SelectItemOption
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 3) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.color")}
          nameKey="Color"
          options={carColorList}
          registerForm={form.register("Color")}
        />
        <SelectItemOption
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 2) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.grade")}
          nameKey="Grade"
          options={carGradeList}
          disabled={!Boolean(form.getValues("Model"))}
          registerForm={form.register("Grade")}
        />
        <SelectItemOption
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 2) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.province")}
          nameKey="Province"
          options={provinceList}
          isRequired={true}
          registerForm={form.register("Province")}
        />
        <SelectItemOption
          disabled={!Boolean(form.getValues("Province"))}
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 2) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.dealer")}
          nameKey="Dealer"
          options={dealerList}
          isRequired={true}
          registerForm={form.register("Dealer")}
        />
        <SelectItemOption
          disabled={!Boolean(form.getValues("Dealer"))}
          labelSx={{
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 500,
          }}
          formControlSx={{
            flexBasis: { xs: "calc((100% / 2) - 16px)", lg: "calc((100% / 4) - 16px)" },
            flexGrow: 1,
          }}
          label={t("FindMyCar.contact.form.branch")}
          nameKey="Branch"
          options={branchList}
          isRequired={true}
          registerForm={form.register("Branch")}
        />
      </Stack>

      <RHFDateTimePickerField
        name="ContactBack"
        label={t("FindMyCar.contact.form.contact-date")}
        placeholder={t("common.droplead.placeholder.date-time")}
        rules={{ required: true }}
      />
      <AcceptPrivacyPolicyCheckbox
        checked={checkedAcceptPolicy}
        onCheckedAcceptPolicy={() => setCheckedAcceptPolicy(!checkedAcceptPolicy)}
      />
      <DividerCustom sx={{ borderColor: color.gray170 }} />
      <Stack
        sx={{
          flexDirection: "row",
          gap: { xs: 2 },
        }}
      >
        <ButtonOutlinedCustom
          onClick={onClickBack}
          label={t("FindMyCar.button.back")}
          sx={{ ...buttonStyleSx, color: color.gray400 }}
        />
        <ButtonCustom
          type="submit"
          label={t("FindMyCar.button.submit-contact")}
          disabled={!checkedAcceptPolicy}
          sx={{
            ...buttonStyleSx,
            color: color.formField,
            [`&.${buttonBaseClasses.disabled}`]: {
              color: color.formField,
              opacity: 0.4,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default FindMyCarContactForm;
