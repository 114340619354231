import { useCallback, useMemo } from "react";
import { buttonBaseClasses, Stack, SxProps, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";

import { useTranslation } from "@/i18n/client";

import ButtonCustom from "@/components/Button/ButtonCustom";
import ButtonOutlinedCustom from "@/components/Button/ButtonOutlinedCustom";
import { DividerCustom } from "@/components/Divider/DividerCustom";
import { useFindMyCarStore } from "@/contexts/useFindMyCar";
import { FindMyCarAdviseStepEnum, FindMyCarPageEnum } from "@/libs/enum/find-my-car.enum";
import color from "@/styles/color";
import LinearDeterminate from "@/components/Progress/LinearDeterminate";

import StepRegion from "./AdviseStep/StepRegion";
import StepProvince from "./AdviseStep/StepProvince";
import StepBudget from "./AdviseStep/StepBudget";
import StepCarType from "./AdviseStep/StepCarType";
import { useQuery } from "@tanstack/react-query";
import { MasterServices } from "@/libs/services/master/master.service";
import { MasterDataSearchKeyWordEnum } from "@/libs/enum/master-search-key.enum";
import { useRouter } from "next/navigation";
import { useWantToBuyStore } from "@/contexts/useWantToBuy";
import { PagePath } from "@/libs/constant/path";
import { setCookie } from "cookies-next";
import { UsedCarFilter } from "@/libs/constant/key-localstorage.constant";
import { MasterData } from "@/libs/types/master-data.type";
import useGetMasterData from "@/hooks/useMasterData";
import { ViewFilter } from "@/libs/types/want-to-buy.type";
import { usePageLoadingStore } from "@/contexts/usePageLoading";
import { _get } from "@/libs/utils/helper";

const PROGRESS_PER_STEP = 25;

const buttonStyleSx: SxProps = {
  height: { xs: "40px", md: "64px" },
  fontSize: { xs: "0.875rem", md: "1.25rem" },
  lineHeight: { xs: "1.3125rem", md: "1.4375rem" },
  padding: 0,
  width: "100%",
  color: color.formField,
};

const FindMyCarAdvise = () => {
  const { t } = useTranslation();
  const route = useRouter();
  const {
    adviseFilter,
    currentAdviseStep,
    navigateAdviseStep,
    setCurrentPage,
    setShowFindMyCarDrawer,
  } = useFindMyCarStore();
  const { setLoading } = usePageLoadingStore();
  const { generateUrlSlug, setViewFilter } = useWantToBuyStore();

  const { data: getRegionData } = useGetMasterData({
    SearchKeyword: MasterDataSearchKeyWordEnum.REGION,
  });

  const { data: getCarTypeData } = useGetMasterData({
    SearchKeyword: MasterDataSearchKeyWordEnum.CAR_TYPE,
  });

  const { data: getCarFuelTypeData } = useGetMasterData({
    SearchKeyword: MasterDataSearchKeyWordEnum.FUEL_TYPE,
  });

  const { data: getProvinceData } = useQuery<MasterData[]>({
    queryKey: [MasterDataSearchKeyWordEnum.PROVINCE, adviseFilter.region],
    queryFn: () =>
      MasterServices.getMasterData({
        SearchKeyword: MasterDataSearchKeyWordEnum.PROVINCE,
        Region: adviseFilter.region,
      }).then((res) => res.SearchFilter),
    enabled: Boolean(adviseFilter.region),
  });

  const onSubmitAdvise = useCallback(() => {
    const isCertified = _get(adviseFilter, "CarCertified.Name", undefined) === "IsCertified";
    setViewFilter({
      Province: adviseFilter.Province
        ? ({ Name: adviseFilter.Province.Name } as MasterData)
        : undefined,
      CarType:
        adviseFilter.CarType && !isCertified
          ? ({ Name: adviseFilter.CarType.Name } as MasterData)
          : undefined,
      Price: adviseFilter.Price,
    } as ViewFilter);
    const urlSlug = generateUrlSlug();
    const filter = JSON.stringify({
      FuelType: adviseFilter.FuelType,
      ...(isCertified && {
        isCertified: isCertified,
      }),
    });
    setCookie(UsedCarFilter, filter);
    setShowFindMyCarDrawer(false);
    setLoading();
    route.push(`${PagePath.wantToBuy}/${urlSlug}`);
  }, [adviseFilter, generateUrlSlug, route]);

  const handleClickBack = useCallback(() => {
    if (currentAdviseStep === FindMyCarAdviseStepEnum.Region) {
      setCurrentPage(FindMyCarPageEnum.Landing);
    } else {
      navigateAdviseStep("back");
    }
  }, [currentAdviseStep, navigateAdviseStep, setCurrentPage]);

  const handleClickNextStep = useCallback(() => {
    if (currentAdviseStep === FindMyCarAdviseStepEnum.CarType) {
      onSubmitAdvise();
    } else {
      navigateAdviseStep("next");
    }
  }, [currentAdviseStep, navigateAdviseStep, onSubmitAdvise]);

  const activeAdviceStep = useMemo(() => {
    return Object.values(FindMyCarAdviseStepEnum).indexOf(currentAdviseStep);
  }, [currentAdviseStep]);

  const titleStep = useMemo(() => {
    return (
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: "1rem", lg: "1.5rem" },
          color: color.black200,
          textAlign: "center",
        }}
      >
        <Typography
          component={"span"}
          sx={{
            fontWeight: 600,
            fontSize: { xs: "1rem", lg: "1.5rem" },
            color: color.red,
          }}
        >
          {/* <Trans i18nKey="FindMyCar.advise.step.title" count={step}>
            {{ step }}
          </Trans> */}
        </Typography>
        {t(`FindMyCar.advise.step.${currentAdviseStep}`)}
      </Typography>
    );
  }, [activeAdviceStep, currentAdviseStep, t]);

  const disabledButton = useMemo(() => {
    switch (currentAdviseStep) {
      case FindMyCarAdviseStepEnum.Region:
        return !adviseFilter?.region;
      case FindMyCarAdviseStepEnum.Province:
        return !adviseFilter?.Province;
      case FindMyCarAdviseStepEnum.Budget:
        return !adviseFilter?.budget || adviseFilter.budget === "";
      case FindMyCarAdviseStepEnum.CarType:
        return !(adviseFilter?.FuelType && adviseFilter?.CarType);
      default:
        return false;
    }
  }, [adviseFilter, currentAdviseStep]);

  const regionList = useMemo(() => {
    return getRegionData?.filter((item) => !item.Name.toLowerCase().includes("all")) ?? [];
  }, [getRegionData]);

  const carFuelTypeList = useMemo(() => {
    return getCarFuelTypeData?.filter((item) => !item.Name.toLowerCase().includes("all")) ?? [];
  }, [getCarFuelTypeData]);

  const provinceList = useMemo(() => {
    return getProvinceData?.filter((item) => !item.Name.toLowerCase().includes("all")) ?? [];
  }, [getProvinceData]);

  const carTypeList = useMemo(() => {
    return getCarTypeData ?? [];
  }, [getCarTypeData]);

  return (
    <Grow in unmountOnExit>
      <Stack
        sx={{
          gap: { xs: 3 },
        }}
      >
        {/* <StepperCustom activeStep={activeAdviceStep} steps={adviseSteps} /> */}
        <LinearDeterminate progress={activeAdviceStep * PROGRESS_PER_STEP} />

        <Fade in unmountOnExit key={`${currentAdviseStep}`}>
          <Stack sx={{ gap: 3 }}>
            {titleStep}
            {currentAdviseStep === FindMyCarAdviseStepEnum.Region && (
              <StepRegion regionList={regionList} />
            )}
            {currentAdviseStep === FindMyCarAdviseStepEnum.Province && (
              <StepProvince provinceList={provinceList} />
            )}
            {currentAdviseStep === FindMyCarAdviseStepEnum.Budget && <StepBudget />}
            {currentAdviseStep === FindMyCarAdviseStepEnum.CarType && (
              <StepCarType carFuelTypeList={carFuelTypeList} carTypeList={carTypeList} />
            )}
          </Stack>
        </Fade>
        <DividerCustom sx={{ borderColor: color.gray170 }} />
        <Stack
          sx={{
            flexDirection: "row",
            gap: { xs: 2 },
          }}
        >
          <ButtonOutlinedCustom
            onClick={handleClickBack}
            label={t("FindMyCar.button.back")}
            sx={{ ...buttonStyleSx, color: color.gray400 }}
          />

          <ButtonCustom
            disabled={disabledButton}
            onClick={handleClickNextStep}
            label={t(
              `FindMyCar.button.${currentAdviseStep === FindMyCarAdviseStepEnum.CarType ? "submit-advise" : "next"}`,
            )}
            sx={{
              ...buttonStyleSx,
              color: color.formField,
              [`&.${buttonBaseClasses.disabled}`]: {
                color: color.formField,
                opacity: 0.4,
              },
            }}
          />
        </Stack>
      </Stack>
    </Grow>
  );
};

export default FindMyCarAdvise;
